import { Component } from '@angular/core';
import {TranslationService} from './modules/i18n';
// language list
import {locale as enLang} from './modules/i18n/vocabs/en';
import {locale as arLang} from './modules/i18n/vocabs/ar';

import * as AOS from 'aos';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ponte';

   constructor( private translationService: TranslationService,) {
    AOS.init();
    // register translations
    this.translationService.loadTranslations(
      enLang,
      arLang,
    );
    }
}
