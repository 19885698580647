// USA
export const locale = {
  lang: 'en',
  data: {
NAVBAR: {
    OUR_PRODUCT: "Our Product",
    HOW_IT_WORKS: "How it works?",
    FEATURES: "Features",
    PARTNERS: "Partners",
    STORIES: "Stories",
    BECOME_A_PARTNER: "Become a Partner!",
    CONTACT_US: "Contact us"
  },
  HEROSECTION:{
      PART1: "Your Loyalty",
      PART2: "Rewards, All Under ",
      PART3: "One Roof"
  },
  HEADERSECTIONBTN: {
    DOWNLOADAPP: 'Download App'
  },
  FEATURESECTION: {
    FEATURETEXT: 'Discover the Heartbeat of your rewards',
    FEATUREHEADING:'Features'
  },
  WORK:{
    WORKTEXT: 'Discover the magic behind our product',
    WORKHEADING: 'How PointEX works?',
    WORKREGISTER: 'Register',
    REGISTERTEXT_PART1: 'Register in one step by phone ',
    REGISTERTEXT_PART2: 'number',
    WORKTRANSFER: 'Transfer',
    TRANSFERTEXT_PART1:'Transfer PointEX to registered',
    TRANSFERTEXT_PART2: 'partners',
    WORKVIEW: 'View',
    VIEWTEXT_PART1: 'Have 360 view of registered',
    VIEWTEXT_PART2: 'partners points',
    WORKREDEEM: 'Redeem',
    REDEEMTEXT_PART1: 'Redeem Rewards, Reap the',
    REDEEMTEXT_PART2: 'Benefits!'

  },
  INSTANTAWARD: {
    HEADING: 'Enjoy an Instant award!',
    INSTANTBTN: 'Register Now!',
    MOBILEHEADING_PART1: 'Enjoy an Instant',
    MOBILEHEADING_PART2: 'award!'
  },
  VALUEDPARTNER:{
    PARTNERTEXT: 'Elevating Excellence Together',
    PARTNERHEADING: 'Meet Our Valued Partners!',
    PARTNERBTN: 'Become a Partner',
    ABOUT: 'About..',
    ABOUTTEXT: ' AlFursan members enjoy a world of benefits: reward tickets, lounge access, extra luggage, priority wait-listing, and more. Plus, as a Saudia SkyTeam member, you can earn and redeem miles globally.Explore offers from our exclusive partners, including SkyTeamairlines, banks, hotels, car rentals, and telecom, to boost your miles and enhance your AlFursan experience.',
    GAINPOINTS: 'How to gain points?',
    GAINPOINTTEXT: 'Join AlFursan for exclusive rewards, lounge access, extra baggage, and faster holiday redemptions with Silver and Gold Tiers.'
  },
  BLOGS:{
    BLOGTEXT: 'Stories that Spark Imagination',
    BLOGHEADING: 'Our Blog'
  },
  NEWSLETTER: {
    NEWSLETTERTEXT: 'Unlock the Secrets',
    NEWSLETTERHEADING: 'Join Our Newsletter for Exclusive Insights!',
    EMAIL: 'Enter your email',
    SUBSCRIBE: 'Subscribe'
  },
  FOOTER: {
    OURPRODUCT: 'Our Product',
    DOWNLOADAPP:'Download App',
    PRICING: 'Pricing',
    TERMS: 'Terms & Conditions',
    PARTNER: 'Become a Partner!',
    CONTACTUS: 'Contact us'
  },
  PARTNER: {
    PARTNERSIDEVIEW: {
      SIDETEXT_PART1: 'Let’s scale your ',
      SIDETEXT_PART2: 'brand, together!'
    },
    PARTNERHEAD: {
      PARTNERHEADING: 'Join us as Partner',
      PARTNERTEXT: 'If you wish to join PointEX platform as a partner and provide loyalty points exchange services, please fill out the form below, and we will get in touch with you soon.',
    },
    PARTNERFORM: {
      COMPANYNAME:'Company Name ',
      COMPANYINDUSTRY: 'Company Industry',
      EMAIL:'Email',
      PHONE: 'Phone/Mobile Number ',
      ADDRESS: 'Address (Street, District, City)',
      COMPANYWEBSITE: 'Company Website',
      TYPEHERE: 'Type here',
    },
    PARTNERFORMBTN: {
      SUBMIT: 'Submit Request'
    },
  },

  SUCCESS: {
    SuCCESHEADING_PART1:"Your request has submitted ",
    SUCCESHEADING_PART2: 'successfully!',
    SUCCESSTEXT: 'our team will get in touch with you soon.',
    SUCCESHOME: 'Go to Homepage'
  },

  CONTACTUS: {
    CONTACTSIDE: {
      CONTACTSIDETEXT: 'Let’s Talk, ask us anything or just say hi'
    },

  CONTACTHEAD: {
    HEADER: 'Contact Us',
    TEXT: 'If you have any inquiries or wish to get in touch with us, please fill out the form below, and we will respond to you as soon as possible',
  },
  CONTACTFORM: {
    NAME: 'Full Name ',
    SUBJECT: 'Subject',
    EMAIL: 'Email',
   MOBILENUMBER: 'Mobile Number',
   TYPEHERE: 'Type here'

  },
  CONTACTFORMBTN:{
    SEND: 'Send'
  },
}


  }

}