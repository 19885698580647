// China
export const locale = {
  lang: 'ar',
  data: {
    
  NAVBAR: {
    OUR_PRODUCT: "لمنتج",
    HOW_IT_WORKS: "كيف يعمل المنتج؟",
    FEATURES: "المميزات",
    PARTNERS: "شركاؤنا",
    STORIES: "المدونة",
    BECOME_A_PARTNER: "انضم كشريك!",
    CONTACT_US: "تواصل معنا"
  },
    HEROSECTION:{
      PART1: "مكافآت الولاء الخاصة",
      PART2: "بك، كلها تحت سقف",
      PART3: " واحد"
  },
   HEADERSECTIONBTN: {
    DOWNLOADAPP: 'سجّل الآن!'
  },
  FEATURESECTION: {
    FEATURETEXT: 'اكتشف نبض مكافآتك',
    FEATUREHEADING:'المميزات'
  },
   WORK:{
    WORKTEXT: 'اكتشف سحر منتجنا',
    WORKHEADING: 'كيف يعمل منتج PointEX!',
    WORKREGISTER: 'سجّل',
    REGISTERTEXT_PART1: 'سجل بخطوة واحدة باستخدام رقم ',
    REGISTERTEXT_PART2: ' الجوال',
    WORKTRANSFER: 'حوّل',
    TRANSFERTEXT_PART1:'حول نقاط PintEX لحساباتك في',
    TRANSFERTEXT_PART2: ' شركائنا المعتمدين',
    WORKVIEW: 'عرض',
    VIEWTEXT_PART1: 'احصل على رؤية 360 درجة لنقاط',
    VIEWTEXT_PART2: ' الشركاء المسجلين',
    WORKREDEEM: 'استبدل',
    REDEEMTEXT_PART1: 'استبدل المكافآت بالمنافع واحصد',
    REDEEMTEXT_PART2: ' الأرباح!'

  },
    INSTANTAWARD: {
    HEADING: 'استمتع بجائزة فورية',
    INSTANTBTN: 'سجّل الآن!',
      MOBILEHEADING_PART1: 'استمتع بجائزة',
    MOBILEHEADING_PART2: ' فورية'
  },
   VALUEDPARTNER:{
    PARTNERTEXT: 'نرتقي بالتميز معا',
    PARTNERHEADING: 'تعرَّف على شركائنا القيمين!',
    PARTNERBTN: 'انضم كشريك!',
    ABOUT: 'حول برنامج الولاء',
    ABOUTTEXT: 'أعضاء الفرسان يتمتعون بعالم من الفوائد: تذاكر مكافأة، وصول إلى صالات الانتظار، حقائب إضافية، الأفضلية في قائمة الانتظار، وأكثر من ذلك. بالإضافة إلى ذلك، كعضو في سكاي تيم سعودية، يمكنك كسب واستبدال الأميال على الصعيدين الوطني والعالمي. استكشف العروض من شركائنا الحصريين، بما في ذلك شركات سكاي تيم، البنوك، الفنادق، شركات تأجير السيارات، وشركات الاتصالات لزيادة أميالك وتعزيز تجربتك مع الفرسان.',
    GAINPOINTS: 'كيف تكسب النقاط؟',
    GAINPOINTTEXT: 'انضم إلى الفرسان للحصول على مكافآت حصرية، والدخول إلى صالات الانتظار، وحمولة إضافية للأمتعة، وتحقيق استردادات عطلتك بسرعة من خلال الفئتين الفضية والذهبية.'
  },
   BLOGS:{
    BLOGTEXT: 'قصص تشعل الخيال',
    BLOGHEADING: 'المدونة'
  },
    NEWSLETTER: {
    NEWSLETTERTEXT: 'اكتشف الأسرار',
    NEWSLETTERHEADING: 'انضم إلى نشرتنا للحصول على رؤى حصرية!',
    EMAIL: 'ادخل بريدك الالكتروني',
    SUBSCRIBE: 'اشترك'
  },
   FOOTER: {
    OURPRODUCT: 'المنتج',
    DOWNLOADAPP:'حمل التطبيق!',
    PRICING: 'التسعير',
    TERMS: 'الشروط والأحكام',
    PARTNER: 'انضم كشريك!',
    CONTACTUS: 'تواصل معنا'
  },

  PARTNER: {
    PARTNERSIDEVIEW: {
      SIDETEXT_PART1: 'هيا نوسع علامتك',
      SIDETEXT_PART2: 'التجارية معا!'
    },
    PARTNERHEAD: {
      PARTNERHEADING: 'انضم لنا كشريك!',
      PARTNERTEXT: 'إذا كنت ترغب في الانضمام إلى منصة PointEX كشريك وتقديم خدمات تبادل نقاط الولاء، يُرجى ملء النموذج أدناه، وسنتواصل معك قريبًا.',
    },
    PARTNERFORM: {
      COMPANYNAME:'اسم الشركة',
      COMPANYINDUSTRY: 'مجال الشركة',
      EMAIL:'البريد الإلكتروني',
      PHONE: 'رقم الهاتف/الجوال',
      ADDRESS: 'العنوان  (الشارع، الحي، المدينة)',
      COMPANYWEBSITE: 'الموقع الإلكتروني للشركة',
      TYPEHERE: 'حول الشركة',
    },
    PARTNERFORMBTN: {
      SUBMIT: 'تقديم الطلب'
    },
  },
   SUCCESS: {
    SuCCESHEADING_PART1:"تم تقديم طلبك",
    SUCCESHEADING_PART2: 'بنجاح',
    SUCCESSTEXT: 'فريقنا سيتواصل معك في أقرب وقت!',
    SUCCESHOME: 'العودة إلى الصفحة الرئيسية'
  },

   CONTACTUS: {
    CONTACTSIDE: {
      CONTACTSIDETEXT: 'هيا نوسع علامتك التجارية معا!'
    },

  CONTACTHEAD: {
    HEADER: 'تواصل معنا',
    TEXT: 'اذا كان لديك أي استفسار او تود التواصل معنا، يرجى ملء النموذج أدناه وسوف نقوم بالرد عليك في أقرب وقت ممكن',
  },
  CONTACTFORM: {
    NAME: 'الاسم الكامل',
    SUBJECT: 'الموضوع',
    EMAIL: 'البريد الإلكتروني',
   MOBILENUMBER: 'رقم الهاتف/الجوال',
   TYPEHERE: 'الرسالة'

  },
  CONTACTFORMBTN:{
    SEND: 'تقديم الطلب'
  },
}
  },
};
